import "../../../styles/footer.css";
import api from "../../../services/api";

import { useQuery } from "react-query";

export default function Footer() {
  const { data, isLoading } = useQuery(
    "FOOTER",
    async () => {
      const { data } = await api.get(`/footer`);
      return data.res;
    },
    { staleTime: 1000 * 1000 * 60 * 12, cacheTime: 1000 * 1000 * 60 * 12 }
  );

  return (
    <section className="main-footer" id="ancoraRodape">
      {isLoading && <p>Carregando...</p>}
      {data &&
        data.map((footer) => (
          <div key={footer.ID} className="footer">
            <div className="footer-div">
              <div className="div-list">
                <ul className="list">
                  <h2>Atendimento</h2>
                  <li>
                    <img
                      src="/icons/Atendimento.svg"
                      alt="logo localização"
                      className="svg-footer"
                    />
                    {footer.HORARIO_ESIC != undefined && footer.HORARIO_ESIC}
                  </li>

                  <li>
                    <img
                      src="/icons/envelope.svg"
                      alt="logo email"
                      className="svg-footer img-aten"
                    />
                    {footer.SITE_EMAIL_FALECOMNOSCO}
                  </li>
                  <li>
                    <img
                      src="/icons/telephone.svg"
                      alt="logo contato"
                      className="svg-footer img-aten"
                    />
                    {footer.TELEFONE_EESIC}
                  </li>
                </ul>

                <ul className="list">
                  <h2>
                    Localização -
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/jeuYWsWDzqBTVw2x9"
                      rel="noreferrer"
                    >
                      Localize
                    </a>
                  </h2>
                  <li>
                    <img
                      src="/icons/geo-alt.svg"
                      alt="logo localização"
                      className="svg-footer"
                    />
                    {footer.ENDERECO}
                  </li>
                  <li>
                    <img
                      src="/icons/MapaB.svg"
                      alt="logo MAPA"
                      className="svg-footer"
                    />
                    {footer.CIDADE}-{footer.UF} - CEP: {footer.CEP}
                  </li>
                  <li>
                    <img
                      src="/icons/layers.svg"
                      alt="logo CNPJ"
                      className="svg-footer"
                    />
                    CNPJ: {footer.CNPJ}
                  </li>
                </ul>

                <ul className="list">
                  <h2>
                    E-SIC -
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/jeuYWsWDzqBTVw2x9"
                      rel="noreferrer"
                    >
                      Localize
                    </a>
                  </h2>
                  <li>
                    <img
                      src="/icons/geo-alt.svg"
                      alt="logo localização"
                      className="svg-footer"
                    />
                    {footer.ENDERECO}
                  </li>
                  <li>
                    <img
                      src="/icons/envelope.svg"
                      alt="logo email"
                      className="svg-footer"
                    />
                    {footer.EMAIL_ESIC}
                  </li>
                </ul>

                <ul className="list">
                  <h2>
                    Ouvidoria -
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/jeuYWsWDzqBTVw2x9"
                      rel="noreferrer"
                    >
                      Localize
                    </a>
                  </h2>
                  <li>
                    <img
                      src="/icons/geo-alt.svg"
                      alt="logo localização"
                      className="svg-footer"
                    />
                    {footer.ENDERECO}
                  </li>
                  <li>
                    <img
                      src="/icons/envelope.svg"
                      alt="logo email"
                      className="svg-footer"
                    />
                    {footer.EMAIL_OUVIDORIA}
                  </li>
                </ul>
              </div>

              <div className="card-social">
                {footer.LINK_WHATSAPP != undefined &&
                  footer.LINK_WHATSAPP != "" && (
                    <a
                      href={footer.LINK_WHATSAPP}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-social"
                    >
                      <img
                        src="/icons/whatsapp.svg"
                        alt="logo WhatsApp"
                        className="svg-footer-social"
                        title="WhatsApp"
                      />
                      WhatsApp
                    </a>
                  )}

                {footer.LINK_INSTAGRAM != undefined &&
                  footer.LINK_INSTAGRAM != "" && (
                    <a
                      href={footer.LINK_INSTAGRAM}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-social"
                    >
                      <img
                        src="/icons/instagramWhite.svg"
                        alt="logo Instagram"
                        className="svg-footer-social"
                        title="Instagram"
                      />
                      Instagram
                    </a>
                  )}

                {footer.LINK_FACEBOOK != undefined &&
                  footer.LINK_FACEBOOK != "" && (
                    <a
                      href={footer.LINK_FACEBOOK}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-social"
                    >
                      <img
                        src="/icons/facebookWhite.svg"
                        alt="logo facebook"
                        className="svg-footer-social"
                        title="facebook"
                      />
                      Facebook
                    </a>
                  )}

                <a
                  href="https://www.youtube.com/@camaramunicipaldesaoroberto"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/youtube.svg"
                    alt="logo youtube"
                    className="svg-footer-social"
                    title="youtube"
                  />
                  Youtube
                </a>
              </div>
              <div className="link-end-footer">
                <p>
                  <a
                    href="https://www.instagram.com/workcenter.consultoria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Workcenter {"  "}
                  </a>
                  &copy; {new Date().getFullYear()}. E todos os direitos
                  reservados.
                </p>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
}
